<template>
  <section class="view">
    <div class="background">
      <iframe
      class="cal"
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FPhoenix&bgcolor=%23ecfbfe&showTitle=0&showCalendars=0&src=Y18wMzZjMDhlZDYwOTAxYzdkOWUzMThmYjJmMjE0OWRmMDBiMTZiMDhmOWIyMmFlZWQ5NGEzNzgwOTFlZjAwZTYxQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23009688&color=%230B8043"
        style="
          border: solid 1px #777;
          border-radius: 20px;
          -webkit-box-shadow: 3px 5px 6px white;
          -moz-box-shadow: 3px 5px 6px white;
          box-shadow: 3px 3px 10px white;
        "
        width="800"
        height="600"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  </section>
</template>

<script></script>

<style scoped>
.view {
  height: 100%;
}

.background {
  width: 100%;
  background-image: url("../assets/sailboat.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.cal {
  max-width: 80%;
  max-height: 80%;
}
</style>
