<template>
    <div id="nautical-bowls">
      <app-header id="app-header"></app-header>
      <main id="main-content">
        <router-view></router-view>
      </main>
      <app-footer id="app-footer"></app-footer>
    </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppHeader, AppFooter
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  overflow: hidden;
}

</style>
