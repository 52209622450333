<template>
  <section class="view">
    <div class="ocean-background">
      <div
        style="margin-bottom: 2%; margin-top: 0%"
        class="container container2"
      >
        <div class="anchor-flex">
          <img src="../assets/anchor.png" alt="dark blue anchor" class="anchor" />
          <h3 style="margin-bottom: 0px; margin-top: 0px">Contact Us</h3>
          <img src="../assets/anchor.png" alt="dark blue anchor" class="anchor" />
        </div>
        <p style="margin-bottom: 0px">We would love to hear from you!</p>
        <p style="margin-bottom: 0px; margin-top: 0px" class="hide">
          Email us at: danielle.miglio@nauticalbowls.com Or Call us at:
          623-806-6020
        </p>
    
        <p style="margin-bottom: 0px; margin-top: 0px" class="hide2">
          Email us at:
          <span style="font-size: 15px; font-family: Poppins, sans-serif"
            >danielle.miglio @nauticalbowls.com</span
          >

        </p>
        <p style="margin-bottom: 0px; margin-top: 0px" class="hide2">
         Call us at: 623-806-6020
        </p>
        <h3 style="margin-bottom: 0px; margin-top: 0px" class="address">
          10030 N McDowell RD. Suite #150, Avondale, AZ, 85323
        </h3>

        <div class="socials-container margin">
          <a
            href="https://www.facebook.com/nauticalbowlsphoenixwestvalley"
            target="_BLANK"
          >
            <img
              src="../assets/blue-facebook-logo.png"
              alt="blue facebook logo"
              class="socials-btn"
            />
          </a>

          <a
            href="https://www.instagram.com/nauticalbowlsphoenixwestvalley"
            target="_BLANK"
          >
            <img
              src="../assets/blue-insta-logo.png"
              alt="blue instagram logo"
              class="socials-btn"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="map-container">
      <img src="../assets/map.png" alt="map of where the store is located" class="map" />
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>

<style scoped>
.map-container {
  background-image: url("../assets/shiplap.png");
  width: 100%;
}

.map {
  width: 200px;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 30px;
  -webkit-box-shadow: 3px 5px 6px #0e82a7;
  -moz-box-shadow: 3px 5px 6px #0e82a7;
  box-shadow: 3px 3px 10px #0e82a7;
  border: 5px solid #0e82a7;
}

.socials-container {
  display: flex;
  flex-direction: row;
  gap: 18px;
}

.socials-btn {
  width: 30px;
}

.socials-btn:hover {
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

.container2 {
  background-color: #ecfbfee4;
  margin-bottom: 0%;
  margin-top: 2%;
}

.anchor {
  width: 70px;
  height: 70px;
}

.anchor-flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  gap: 20px;
}

.address {
  font-size: 35px;
  width: 85%;
}

.ocean-background {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/sailboat.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media only screen and (max-width: 412px) {
  .anchor {
    display: none;
  }
}

@media only screen and (max-width: 412px) {
  .hide {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .address {
    font-size: 25px;
    width: 85%;
  }
  .anchor {
    width: 40px;
    height: 40px;
  }

  .map {
    margin-top: 10%;
  }
}
@media only screen and (min-width: 413px) {
  .hide2 {
    display: none;
  }
}
@media only screen and (min-width: 380px) {
  .map {
    width: 300px;
  }
}

@media only screen and (min-width: 600px) {
  .map {
    width: 500px;
  }
}

@media only screen and (min-width: 900px) {
  .map {
    width: 800px;
  }
}
</style>
