<template>
     <div class="socials-container margin">
          <a
            href="https://www.facebook.com/nauticalbowlsphoenixwestvalley"
            target="_BLANK"
          >
            <img
              src="../assets/blue-facebook-logo.png"
              alt="blue facebook logo"
              class="socials-btn"
            />
          </a>

          <a
            href="https://www.instagram.com/nauticalbowlsphoenixwestvalley"
            target="_BLANK"
          >
            <img
              src="../assets/blue-insta-logo.png"
              alt="blue instagram logo"
              class="socials-btn"
            />
          </a>
        </div>
</template>

<style scoped>
.socials-container {
  display: flex;
  flex-direction: row;
  gap: 18px;
}

.socials-btn {
  width: 55px;
}

.socials-btn:hover {
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

</style>