<template>
  <header id="app-header">
    <nav>
      <router-link class="logo-section" to="/">
        <img class="logo" src="../assets/dark-logo.png" alt="nautical bowls logo"/>
      </router-link>
      <ul v-show="!isMobile" class="navigation">
        <li><router-link class="link" to="/events">Events</router-link></li>
        <li><router-link class="link" to="/contact">Contact</router-link></li>
        <img class="anchor" src="../assets/anchor.png" alt="dark blue anchor"/>
      </ul>
      <div class="hamburger-menu">
        <font-awesome-icon
          id="hamburger-icon"
          v-show="isMobile"
          v-on:click="toggleMobileNav"
          v-bind:class="{ 'icon-active': isMobileNavEnabled }"
          icon="fa-solid fa-bars"
        />
      </div>
      <transition name="mobile-nav">
        <ul v-show="isMobileNavEnabled" class="dropdown-nav">
          <li>
            <router-link v-on:click="disableMobileNav" class="link" to="/events"
              >Events</router-link
            >
          </li>
          <li>
            <router-link
              v-on:click="disableMobileNav"
              class="link"
              to="/contact"
              >Contact</router-link
            >
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      scrollPosition: null,
      isMobile: true,
      isMobileNavEnabled: false,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    toggleMobileNav() {
      this.isMobileNavEnabled = !this.isMobileNavEnabled;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.isMobileNavEnabled = false;
      }
    },
    disableMobileNav() {
      this.isMobileNavEnabled = false;
    },
  },
};
</script>

<style scoped>
.anchor {
  width: 28px;
  padding: 16px;
  padding-right: 0px;
  margin-left: 16px;
}

#app-header {
  gap: 15px;
  z-index: 99;
  height: 70px; /* make sure to update top of .dropdown-nav */
  box-shadow: 0 0 2px 0 whitesmoke, 0 2px 7px 0 grey;
}

nav {
  width: 90%;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  transition: 0.5s ease all;
  justify-content: space-between;
  margin: 0 auto;
}

.navigation {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

ul,
.link {
  color: #269dc2;
  list-style: none;
  text-decoration: none;
}

li {
  text-transform: uppercase;
  padding: 16px;
  margin-left: 16px;
}

.link {
  border-bottom: 1px solid transparent;
  color: #0381a5;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
  font-weight: 700;
}

.link:hover {
  color: #0381a5;
  border-color: #0381a5;
  cursor: pointer;
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

.logo {
  width: 200px;
}

.logo:hover {
  cursor: pointer;
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

.logo-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.hamburger-menu {
  position: absolute;
  top: 24px;
  right: 24px;
  color: #0381a5;
}

#hamburger-icon {
  cursor: pointer;
  font-size: 24px;
  transition: 0.8s ease all;
}

.icon-active {
  transform: rotate(180deg);
}

.dropdown-nav {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: fixed;
  width: 100%;
  max-width: 250px;
  height: 100%;
  background-color: #ecfbfe;
  top: 70px; /* make sure this matches height of app-header */
  left: 0;
  padding: 0px;
  margin: 0px;
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(-250px);
}

.mobile-nav-enter-to {
  transform: translateX(0px);
}

.dropdown-nav > li {
  margin-left: 5px;
}

.dropdown-nav > li > .link {
  color: #0381a5;
}

.dropdown-nav > li > .link:hover {
  color: rgb(78, 111, 211);
  cursor: pointer;
}

@media only screen and (max-width: 300px) {
  .logo {
    width: 150px;
  }
}

@media only screen and (max-width: 600px) {
  #app-header {
    height: 60px;
  }

  .dropdown-nav {
    top: 60px;
  }

  .hamburger-menu {
    position: absolute;
    top: 18.5px;
    right: 24px;
    color: #0381a5;
  }
}
</style>
