import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import EventView from "@/views/EventView";
import ContactView from "@/views/ContactView";

const routes = [
    {
      path: "/",
      name: "HomeView",
      component: HomeView,
    },
    {
      path: "/events",
      name: "EventView",
      component: EventView,
    },
    {
      path: "/contact",
      name: "ContactView",
      component: ContactView,
    },
  ];
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  
  export default router;
  