<template>
    <footer id="app-footer">
      <div class="footer-row">
        <a href="https://www.southwestvalleychamber.org/" target="_BLANK"><img class="sizing" src="../assets/svcc.png" alt="southwest valley chamber of commerce logo"/></a>
        <p class="footer">Member of Southwest Valley Chamber</p>
      </div>
    </footer>
  </template>
  
<style scoped>
#app-footer {
  height: 40px;
  display: flex;
}

.footer {
    font-size: 16px;
    margin: 0px;
}

.footer-row {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: 1%;
  width: 100%;
}

.sizing {
  width: 30px;
}

</style>